<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-dw8">
        <!-- 水果呀 -->
        <div class="data">
          <h3>第六球</h3>
          <ul class="custom_ul">
            <li v-for="ele in rowsData[0].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">
                <div :class="'blueball b'+ele.label">{{ele.label}}</div>
              </ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>

        </div>
        <!-- -------- -->
        <!-- 第二个data -->
        <div class="data">
          <ul>
            <li v-for="ele in rowsData[1].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash lianTip">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

            <li>
              <ol class="td_empty" style="width:100%;height:17px;"></ol>
            </li>
          </ul>
        </div>
        <!-- ------- -->

      </div>
      <!-- 预设 -->
      <yushe />
      <!--  -->
      <!-- 表格  -->

      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">第一球</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">第二球</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三球</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四球</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五球</td>
              <td :class="qiuIndex==6?'selected':''" @click="changeQiu(6)">第六球</td>
              <td :class="qiuIndex==7?'selected':''" @click="changeQiu(7)">第七球</td>
              <td :class="qiuIndex==8?'selected':''" @click="changeQiu(8)">第八球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <!-- ds_d  ds_x -->
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <!-- dx_d  dx_x -->
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">总和</th>
              <!-- d   x -->
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">总和单双</th>
              <!-- sum_dx_d sum_dx_s -->
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">总和大小</th>
              <th :class="ltIndex==6?'selected':''" @click="changeLutuData(6)">合单双</th>
              <th :class="ltIndex==7?'selected':''" @click="changeLutuData(7)">大小尾</th>
              <th :class="ltIndex==8?'selected':''" @click="changeLutuData(8)">色波</th>
              <th :class="ltIndex==9?'selected':''" @click="changeLutuData(9)">龙虎</th>
              <th :class="ltIndex==10?'selected':''" @click="changeLutuData(10)">四季</th>
            </tr>
          </tbody>
        </table>
        <!-- 单双 -->
        <table v-if="tableType==1" colspan="0" id="lt_ds" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span v-if="!isNumber(ele)" :class="fontColor[ele]">{{ele}}</span>
                <span v-if="isNumber(ele)" :class="fontColor[ele>=23?'大':'小']">{{ele}}</span>
              </td>

            </tr>

          </tbody>
        </table>

        <!--  -->
      </div>

      <!-- table  end -->
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'dw,fwsj,lm',
      ltArr: [],
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "第六球", arr: [] },
        { title: "第六球", arr: [] },
      ],
      labelArr1: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      labelArr2: [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      labelArr3: [
        "单",
        "双",
        "大",
        "小",
        "尾大",
        "尾小",
        "合数单",
        "合数双",
        "春",
        "夏",
        "秋",
        "冬",
        "东",
        "南",
        "西",
        "北",
        "中",
        "发",
        "白",
      ],
      lutu1: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu2: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu3: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu4: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu5: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu6: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu7: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
      lutu8: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 第六球
        let arr1 = this.filterData(resAArr, 1543, 1562, this.labelArr1);
        this.rowsData[0].arr = arr1;

        let arr2 = this.ddFilterData1(
          resAArr,
          [
            1623, 1624, 1621, 1622, 1625, 1626, 1627, 1628, 4010, 4011, 4012,
            4013, 1749, 1750, 1751, 1752, 1720, 1721, 1722,
          ],
          this.labelArr3
        );

        this.rowsData[1].arr = arr2;

        this.$forceUpdate();
      },
    },
  },
  created() {},
  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu6 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu7 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      this.lutu8 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
        arr7: [[], [], [], [], []],
        arr8: [[], [], [], [], []],
        arr9: [[], [], [], [], []],
        arr10: [[], [], [], [], []],
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              heds1 = [[]],
              dxw1 = [[]],
              sebo1 = [[]],
              siji1 = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              heds2 = [[]],
              dxw2 = [[]],
              sebo2 = [[]],
              siji2 = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              heds3 = [[]],
              dxw3 = [[]],
              sebo3 = [[]],
              siji3 = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              heds4 = [[]],
              dxw4 = [[]],
              sebo4 = [[]],
              siji4 = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              heds5 = [[]],
              dxw5 = [[]],
              sebo5 = [[]],
              siji5 = [[]],
              arr6_ds = [[]],
              arr6_dx = [[]],
              heds6 = [[]],
              dxw6 = [[]],
              sebo6 = [[]],
              siji6 = [[]],
              arr7_ds = [[]],
              arr7_dx = [[]],
              heds7 = [[]],
              dxw7 = [[]],
              sebo7 = [[]],
              siji7 = [[]],
              arr8_ds = [[]],
              arr8_dx = [[]],
              heds8 = [[]],
              dxw8 = [[]],
              sebo8 = [[]],
              siji8 = [[]],
              // ------------------
              arr_lh = [[]],
              arr_zh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = q1 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr1_ds, ds1);
              // 合单双
              this.initlutuData(heds1, this.heshudanshuang(q1));
              // 大小尾
              this.initlutuData(dxw1, this.daxiaowei(q1));
              // 四季
              this.initlutuData(siji1, this.sijion(q1));
              // 色波
              this.initlutuData(sebo1, this.seboon(q1));
              // 大小
              let dx1 = q1 >= 11 ? "大" : "小";
              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = q2 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr2_ds, ds2, true);
              // 合单双
              this.initlutuData(heds2, this.heshudanshuang(q2));
              // 大小尾
              this.initlutuData(dxw2, this.daxiaowei(q2));
              // 四季
              this.initlutuData(siji2, this.sijion(q2));
              // 色波
              this.initlutuData(sebo2, this.seboon(q2));
              // 大小
              let dx2 = q2 >= 11 ? "大" : "小";
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = q3 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr3_ds, ds3);
              // 合单双
              this.initlutuData(heds3, this.heshudanshuang(q3));
              // 大小尾
              this.initlutuData(dxw3, this.daxiaowei(q3));
              // 四季
              this.initlutuData(siji3, this.sijion(q3));
              // 色波
              this.initlutuData(sebo3, this.seboon(q3));
              // 大小
              let dx3 = q3 >= 11 ? "大" : "小";
              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = q4 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr4_ds, ds4);
              // 合单双
              this.initlutuData(heds4, this.heshudanshuang(q4));
              // 大小尾
              this.initlutuData(dxw4, this.daxiaowei(q4));
              // 四季
              this.initlutuData(siji4, this.sijion(q4));
              // 色波
              this.initlutuData(sebo4, this.seboon(q4));
              // 大小
              let dx4 = q4 >= 11 ? "大" : "小";
              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = q5 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr5_ds, ds5);
              // 合单双
              this.initlutuData(heds5, this.heshudanshuang(q5));
              // 大小尾
              this.initlutuData(dxw5, this.daxiaowei(q5));
              // 四季
              this.initlutuData(siji5, this.sijion(q5));
              // 色波
              this.initlutuData(sebo5, this.seboon(q5));
              // 大小
              let dx5 = q5 >= 11 ? "大" : "小";
              this.initlutuData(arr5_dx, dx5);
              // 第六球--------------------------------------
              let q6 = element.codeArr[5];
              let ds6 = q6 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr6_ds, ds6);
              // 合单双
              this.initlutuData(heds6, this.heshudanshuang(q6));
              // 大小尾
              this.initlutuData(dxw6, this.daxiaowei(q6));
              // 四季
              this.initlutuData(siji6, this.sijion(q6));
              // 色波
              this.initlutuData(sebo6, this.seboon(q6));
              // 大小
              let dx6 = q6 >= 11 ? "大" : "小";
              this.initlutuData(arr6_dx, dx6);
              // 第七球
              let q7 = element.codeArr[6];
              let ds7 = q7 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr7_ds, ds7);
              // 合单双
              this.initlutuData(heds7, this.heshudanshuang(q7));
              // 大小尾
              this.initlutuData(dxw7, this.daxiaowei(q7));
              // 四季
              this.initlutuData(siji7, this.sijion(q7));
              // 色波
              this.initlutuData(sebo7, this.seboon(q7));
              // 大小
              let dx7 = q7 >= 11 ? "大" : "小";
              this.initlutuData(arr7_dx, dx7);
              // 第八求
              let q8 = element.codeArr[7];
              let ds8 = q8 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr8_ds, ds8);
              // 合单双
              this.initlutuData(heds8, this.heshudanshuang(q8));
              // 大小尾
              this.initlutuData(dxw8, this.daxiaowei(q8));
              // 四季
              this.initlutuData(siji8, this.sijion(q8));
              // 色波
              this.initlutuData(sebo8, this.seboon(q8));
              // 大小
              let dx8 = q8 >= 11 ? "大" : "小";
              this.initlutuData(arr8_dx, dx8);

              //  龙虎
              let lhstr =
                Number(element.codeArr[0]) - Number(element.codeArr[7]) > 0
                  ? "龙"
                  : "虎";

              this.initlutuData(arr_lh, lhstr);

              // 总和
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              this.initlutuData(arr_zh, sum);
              // 总和大小
              let sumds = sum % 2 == 0 ? "双" : "单";
              let sumdx = "";
              this.initlutuData(arr_zhds, sumds);
              if (sum >= 85 && sum <= 132) {
                sumdx = "大";
              } else if (sum == 84) {
                sumdx = "和";
              } else {
                sumdx = "小";
              }
              this.initlutuData(arr_zhdx, sumdx);
              // ----------------------------------------------------------
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            this.buquanArr(heds1, this.lutu1.arr6);
            this.buquanArr(dxw1, this.lutu1.arr7);
            this.buquanArr(sebo1, this.lutu1.arr8);
            this.buquanArr(siji1, this.lutu1.arr10);

            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            this.buquanArr(heds2, this.lutu2.arr6);
            this.buquanArr(dxw2, this.lutu2.arr7);
            this.buquanArr(sebo2, this.lutu2.arr8);
            this.buquanArr(siji2, this.lutu2.arr10);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            this.buquanArr(heds3, this.lutu3.arr6);
            this.buquanArr(dxw3, this.lutu3.arr7);
            this.buquanArr(sebo3, this.lutu3.arr8);
            this.buquanArr(siji3, this.lutu3.arr10);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            this.buquanArr(heds4, this.lutu4.arr6);
            this.buquanArr(dxw4, this.lutu4.arr7);
            this.buquanArr(sebo4, this.lutu4.arr8);
            this.buquanArr(siji4, this.lutu4.arr10);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            this.buquanArr(heds5, this.lutu5.arr6);
            this.buquanArr(dxw5, this.lutu5.arr7);
            this.buquanArr(sebo5, this.lutu5.arr8);
            this.buquanArr(siji5, this.lutu5.arr10);
            // 第六球
            this.buquanArr(arr6_ds, this.lutu6.arr1);
            this.buquanArr(arr6_dx, this.lutu6.arr2);
            this.buquanArr(heds6, this.lutu6.arr6);
            this.buquanArr(dxw6, this.lutu6.arr7);
            this.buquanArr(sebo6, this.lutu6.arr8);
            this.buquanArr(siji6, this.lutu6.arr10);
            // 第七球
            this.buquanArr(arr7_ds, this.lutu7.arr1);
            this.buquanArr(arr7_dx, this.lutu7.arr2);
            this.buquanArr(heds7, this.lutu7.arr6);
            this.buquanArr(dxw7, this.lutu7.arr7);
            this.buquanArr(sebo7, this.lutu7.arr8);
            this.buquanArr(siji7, this.lutu7.arr10);
            // 第八求
            this.buquanArr(arr8_ds, this.lutu8.arr1);
            this.buquanArr(arr8_dx, this.lutu8.arr2);
            this.buquanArr(heds8, this.lutu8.arr6);
            this.buquanArr(dxw8, this.lutu8.arr7);
            this.buquanArr(sebo8, this.lutu8.arr8);
            this.buquanArr(siji8, this.lutu8.arr10);
            // 总和---------------------------------
            this.buquanArr(arr_zh, this.lutu1.arr3);
            this.buquanArr(arr_zh, this.lutu2.arr3);
            this.buquanArr(arr_zh, this.lutu3.arr3);
            this.buquanArr(arr_zh, this.lutu4.arr3);
            this.buquanArr(arr_zh, this.lutu5.arr3);
            this.buquanArr(arr_zh, this.lutu6.arr3);
            this.buquanArr(arr_zh, this.lutu7.arr3);
            this.buquanArr(arr_zh, this.lutu8.arr3);
            // 龙虎
            this.buquanArr(arr_lh, this.lutu1.arr9);
            this.buquanArr(arr_lh, this.lutu2.arr9);
            this.buquanArr(arr_lh, this.lutu3.arr9);
            this.buquanArr(arr_lh, this.lutu4.arr9);
            this.buquanArr(arr_lh, this.lutu5.arr9);
            this.buquanArr(arr_lh, this.lutu6.arr9);
            this.buquanArr(arr_lh, this.lutu7.arr9);
            this.buquanArr(arr_lh, this.lutu8.arr9);

            this.buquanArr(arr_zhds, this.lutu1.arr4);
            this.buquanArr(arr_zhds, this.lutu2.arr4);
            this.buquanArr(arr_zhds, this.lutu3.arr4);
            this.buquanArr(arr_zhds, this.lutu4.arr4);
            this.buquanArr(arr_zhds, this.lutu5.arr4);
            this.buquanArr(arr_zhds, this.lutu6.arr4);
            this.buquanArr(arr_zhds, this.lutu7.arr4);
            this.buquanArr(arr_zhds, this.lutu8.arr4);

            this.buquanArr(arr_zhdx, this.lutu1.arr5);
            this.buquanArr(arr_zhdx, this.lutu2.arr5);
            this.buquanArr(arr_zhdx, this.lutu3.arr5);
            this.buquanArr(arr_zhdx, this.lutu4.arr5);
            this.buquanArr(arr_zhdx, this.lutu5.arr5);
            this.buquanArr(arr_zhdx, this.lutu6.arr5);
            this.buquanArr(arr_zhdx, this.lutu7.arr5);
            this.buquanArr(arr_zhdx, this.lutu8.arr5);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    // 合单双
    heshudanshuang(num) {
      let hds = "";
      let hsum = 0;
      if (Number(num) < 10) {
        hsum = num;
      } else {
        let _num = num + "";
        hsum = Number(_num[0]) + Number(_num[1]);
      }
      hds = hsum % 2 == 0 ? "双" : "单";
      return hds;
    },
    // 大小尾
    daxiaowei(num) {
      let dxwstr = "";
      let ws = Number(num) > 9 ? num + "" : "0" + num;
      dxwstr = ws[1] >= 5 ? "大" : "小";
      return dxwstr;
    },
    sijion(num) {
      let count = Number(num);
      let str = "";
      if (count <= 5) {
        str = "春";
      } else if (count >= 6 && count <= 10) {
        str = "夏";
      } else if (count >= 11 && count <= 15) {
        str = "秋";
      } else {
        str = "冬";
      }
      return str;
    },
    // 色波
    seboon(num) {
      return Number(num) >= 19 ? "红" : "蓝";
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li {
  width: 25%;
}
</style>